import React from "react"
import styled from "styled-components"

function Compliance() {
  return (
    <Container>
      <WrapperTitle>
        <Title>Privacy Policy</Title>
        <SubTitle>
          At Writers Way Solutions, we prioritize your privacy and ensure that
          we only send text messages to customers who have explicitly requested
          SMS communication. We are committed to maintaining the confidentiality
          and security of your personal information.
        </SubTitle>
      </WrapperTitle>
      <Wrapper>
        <div
          css={`
            display: flex;
            align-items: flex-start;
            flex-direction: column;
            gap: 0.2rem;
          `}
        >
          <SecondaryTitle
            css={`
              margin-bottom: 1rem;
            `}
          >
            Scope
          </SecondaryTitle>
          <ul
            css={`
              display: flex;
              align-items: flex-start;
              flex-direction: column; /* Ensures list items stack vertically */
              gap: 0.2rem;
              list-style-type: none; /* Removes default list style */
              padding-left: 0; /* Removes default padding */
              margin: 0; /* Removes default margin */
            `}
          >
            <li
              css={`
                display: flex;
                align-items: flex-start;
                gap: 0.2rem;
              `}
            >
              <span
                css={`
                  font-size: 16px;
                  line-height: 1.5;
                  color: #111;
                  font-weight: 700;
                `}
              >
                •
              </span>
              <PrivacyText>
                <strong>Message Types:</strong> Customers who opt in will
                receive messages related to account notifications, service
                updates, promotional offers, event reminders, and customer
                support.
              </PrivacyText>
            </li>
            <li
              css={`
                display: flex;
                align-items: flex-start;
                gap: 0.2rem;
              `}
            >
              <span
                css={`
                  font-size: 16px;
                  line-height: 1.5;
                  color: #111;
                  font-weight: 700;
                `}
              >
                •
              </span>
              <PrivacyText>
                <strong>Fees:</strong> Standard message and data rates may
                apply.
              </PrivacyText>
            </li>
            <li
              css={`
                display: flex;
                align-items: flex-start;
                gap: 0.2rem;
              `}
            >
              <span
                css={`
                  font-size: 16px;
                  line-height: 1.5;
                  color: #111;
                  font-weight: 700;
                `}
              >
                •
              </span>
              <PrivacyText>
                <strong>Content Variation:</strong> Messages from Writers Way
                may vary in content based on your interactions and preferences.
              </PrivacyText>
            </li>
            <li
              css={`
                display: flex;
                align-items: flex-start;
                gap: 0.2rem;
              `}
            >
              <span
                css={`
                  font-size: 16px;
                  line-height: 1.5;
                  color: #111;
                  font-weight: 700;
                `}
              >
                •
              </span>
              <PrivacyText>
                <strong>Opt-Out:</strong> To opt out of receiving messages, text
                “STOP” to cancel.
              </PrivacyText>
            </li>
            <li
              css={`
                display: flex;
                align-items: flex-start;
                gap: 0.2rem;
              `}
            >
              <span
                css={`
                  font-size: 16px;
                  line-height: 1.5;
                  color: #111;
                  font-weight: 700;
                `}
              >
                •
              </span>
              <PrivacyText>
                <strong>Assistance:</strong> For help, email
                customersupport@writerswaysolutions.com or call 1-888- 666-4258.
              </PrivacyText>
            </li>
          </ul>
        </div>

        <div
          css={`
            display: flex;
            align-items: flex-start;
            flex-direction: column;
            gap: 0.2rem;
          `}
        >
          <SecondaryTitle>Data Collection</SecondaryTitle>
          <PrivacyText
            css={`
              margin-bottom: 1rem;
            `}
          >
            We collect data through:
          </PrivacyText>
          <ul
            css={`
              display: flex;
              align-items: flex-start;
              flex-direction: column; /* Ensures list items stack vertically */
              gap: 0.2rem;
              list-style-type: none; /* Removes default list style */
              padding-left: 0; /* Removes default padding */
              margin: 0; /* Removes default margin */
            `}
          >
            <li
              css={`
                display: flex;
                align-items: flex-start;
                gap: 0.2rem;
              `}
            >
              <span
                css={`
                  font-size: 16px;
                  line-height: 1.5;
                  color: #111;
                  font-weight: 700;
                `}
              >
                •
              </span>
              <PrivacyText>The Contact Us section on our website</PrivacyText>
            </li>
            <li
              css={`
                display: flex;
                align-items: flex-start;
                gap: 0.2rem;
              `}
            >
              <span
                css={`
                  font-size: 16px;
                  line-height: 1.5;
                  color: #111;
                  font-weight: 700;
                `}
              >
                •
              </span>
              <PrivacyText>Marketing emails</PrivacyText>
            </li>
            <li
              css={`
                display: flex;
                align-items: flex-start;
                gap: 0.2rem;
              `}
            >
              <span
                css={`
                  font-size: 16px;
                  line-height: 1.5;
                  color: #111;
                  font-weight: 700;
                `}
              >
                •
              </span>
              <PrivacyText>Phone conversations</PrivacyText>
            </li>
          </ul>
          <PrivacyText
            css={`
              margin-top: 1rem;
            `}
          >
            This data is used to provide and enhance our services for your
            business or career.
          </PrivacyText>
        </div>
        <div
          css={`
            display: flex;
            align-items: flex-start;
            flex-direction: column;
            gap: 0.2rem;
          `}
        >
          <SecondaryTitle
            css={`
              margin-bottom: 1rem;
            `}
          >
            Privacy and Data Security
          </SecondaryTitle>
          <ul
            css={`
              display: flex;
              align-items: flex-start;
              flex-direction: column; /* Ensures list items stack vertically */
              gap: 0.2rem;
              list-style-type: none; /* Removes default list style */
              padding-left: 0; /* Removes default padding */
              margin: 0; /* Removes default margin */
            `}
          >
            <li
              css={`
                display: flex;
                align-items: flex-start;
                gap: 0.2rem;
              `}
            >
              <span
                css={`
                  font-size: 16px;
                  line-height: 1.5;
                  color: #111;
                  font-weight: 700;
                `}
              >
                •
              </span>
              <PrivacyText>
                We are dedicated to maintaining the confidentiality of user
                phone numbers and related personal information. We will not
                share this information with third parties except with client
                approval.
              </PrivacyText>
            </li>
            <li
              css={`
                display: flex;
                align-items: flex-start;
                gap: 0.2rem;
              `}
            >
              <span
                css={`
                  font-size: 16px;
                  line-height: 1.5;
                  color: #111;
                  font-weight: 700;
                `}
              >
                •
              </span>
              <PrivacyText>
                Writers Way employs appropriate security measures to protect
                user information from unauthorized access or disclosure.
              </PrivacyText>
            </li>
          </ul>
        </div>

        <div
          css={`
            display: flex;
            align-items: flex-start;
            flex-direction: column;
            gap: 0.2rem;
          `}
        >
          <SecondaryTitle
            css={`
              margin-bottom: 1rem;
            `}
          >
            Content Restrictions
          </SecondaryTitle>
          <ul
            css={`
              display: flex;
              align-items: flex-start;
              flex-direction: column; /* Ensures list items stack vertically */
              gap: 0.2rem;
              list-style-type: none; /* Removes default list style */
              padding-left: 0; /* Removes default padding */
              margin: 0; /* Removes default margin */
            `}
          >
            <li
              css={`
                display: flex;
                align-items: flex-start;
                gap: 0.2rem;
              `}
            >
              <span
                css={`
                  font-size: 16px;
                  line-height: 1.5;
                  color: #111;
                  font-weight: 700;
                `}
              >
                •
              </span>
              <PrivacyText>
                Text messages from Writers Way will not contain inappropriate,
                discriminatory, offensive, or illegal content.
              </PrivacyText>
            </li>
            <li
              css={`
                display: flex;
                align-items: flex-start;
                gap: 0.2rem;
              `}
            >
              <span
                css={`
                  font-size: 16px;
                  line-height: 1.5;
                  color: #111;
                  font-weight: 700;
                `}
              >
                •
              </span>
              <PrivacyText>
                Messages will be clear, concise, and relevant to your
                interaction with Writers Way Solutions.
              </PrivacyText>
            </li>
          </ul>
        </div>

        <div
          css={`
            display: flex;
            align-items: flex-start;
            flex-direction: column;
            gap: 0.2rem;
          `}
        >
          <SecondaryTitle
            css={`
              margin-bottom: 1rem;
            `}
          >
            Privacy Notice Changes
          </SecondaryTitle>
          <ul
            css={`
              display: flex;
              align-items: flex-start;
              flex-direction: column; /* Ensures list items stack vertically */
              gap: 0.2rem;
              list-style-type: none; /* Removes default list style */
              padding-left: 0; /* Removes default padding */
              margin: 0; /* Removes default margin */
            `}
          >
            <li
              css={`
                display: flex;
                align-items: flex-start;
                gap: 0.2rem;
              `}
            >
              <span
                css={`
                  font-size: 16px;
                  line-height: 1.5;
                  color: #111;
                  font-weight: 700;
                `}
              >
                •
              </span>
              <PrivacyText>
                This privacy notice may be updated occasionally. The date of the
                most recent update is indicated at the top of this notice.
              </PrivacyText>
            </li>
          </ul>
        </div>
      </Wrapper>
      {/* <Wrapper>
        <TextInfo>
          By providing your mobile phone number and opting in to receive SMS
          notifications, you acknowledge that you have read, understood, and
          agreed to abide by these terms and conditions.
        </TextInfo>
        <TextInfo>
          If you have any questions or concerns regarding our SMS notification
          service or these terms and conditions, please do not hesitate to
          contact us at Company phone number <strong>1-888-666-4258</strong> or
          email us at <strong>customersupport@writerswaysolutions.com</strong>.
        </TextInfo>
        <TextInfo>Thank you for choosing Writers Way Solutions LLC!</TextInfo>
      </Wrapper> */}
    </Container>
  )
}

export default Compliance

const Container = styled.div`
  display: flex;
  flex-direction: column;
  gap: 2rem;
  padding: 4rem calc((100vw - 1200px) / 2);
  padding-top: 2rem;
  @media screen and (max-width: 768px) {
  }
`

const Title = styled.h1`
  color: ${props => props.theme.colors.main1};
`
const SubTitle = styled.h5`
  font-family: ${props => props.theme.fonts.secondary};
  color: ${props => props.theme.colors.main1};
  font-size: 16px;
  line-height: 1.5;
  font-weight: 500;
  text-align: justify;
`
const SecondaryTitle = styled.h6`
  font-family: ${props => props.theme.fonts.secondary};
  color: ${props => props.theme.colors.main1};
  font-size: 20px;
  line-height: 1.5;
  text-align: justify;
`
const PrivacyText = styled.p`
  font-size: 16px;
  line-height: 1.5;
  color: #111;
  font-weight: 400;
  text-align: justify;
`

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 2rem;
`

const WrapperTitle = styled.div`
  display: flex;
  flex-direction: column;
  gap: 1rem;
`
const TextInfo = styled.p`
  font-size: 14px;
  line-height: 1.5;
  color: ${props => props.theme.colors.dark2};
`
